<template>
  <div class="start start--dark">
    <div class="start__head flex aic">
      <img class="start__logo" src="../../assets/images/logo-qat.png" alt=""/>
      <div class="start__links flex aic mla">
        <router-link :to="getLink" class="start__link">{{ getLinkText }}</router-link>
      </div>
    </div>
    <div class="start__inner">
      <form class="start__col start__col--form" action="" method="post" @submit.prevent="handleRegister">
        <h2 v-if="!registerByInviteToken" class="start__title">Organised feedback for <span
            class="start__title--colored">Designers & Developers</span> from QA and Client</h2>
        <h2 class="start__title" v-else>
          <template v-if="inviteData.fullName.trim()">
            {{ inviteData.fullName }} invited you to {{ inviteData.teamName }} at qatool
          </template>
          <template v-else>
            You've been invited to {{ inviteData.teamName }}
          </template>
        </h2>
        <div class="start__desc start__desc--lite">Built and tested across XX projects and YY tickets by RGB Code
          agency.
        </div>
        <div class="start__fields">
          <input class="start__input start__input--dark" v-model="userEmail" name="userEmail" type="email"
                 placeholder="Email" required/>
          <input v-model="referrer" name="referrer" type="hidden"/>
          <button class="start__btn" type="submit" :class="submitted ? 'is-load' : ''" :disabled="submitted">Sign Up
            with 1 click
          </button>
        </div>
        <p v-if="hasError" class="start__error">{{ errorMessage }}</p>
      </form>
      <div class="start__col start__col--side">
        <div class="start__video">
          <img class="start__img" src="../../assets/images/start-img.png" alt=""/>
          <span class="start__watch" @click="runVideo( $event, 'https://www.youtube.com/embed/J---aiyznGQ' )">Watch 2 minutes demo</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import base64_decode from 'locutus/php/url/base64_decode';
import {commonHelper} from '@/_helpers/common-helper';

export default {
  data() {
    return {
      userEmail: '',
      submitted: false,
      response: null,
      referrer: window.location.href + 'tasks',
      hasError: null,
      errorMessage: '',
    };
  },
  created() {
    commonHelper.documentTitle('QA tool to assure quality like never before');
  },
  computed: {
    ...mapGetters(
      {
        getJWT: 'common/getJWT',
        userIsLogged: 'user/userIsLogged',
        getInitialSettings: 'user/getInitialSettings',
      },
    ),
    getLink() {
      return this.userIsLogged && this.getJWT ?
          {
            name: 'Project',
            params: {
              teamId: this.getInitialSettings.team.team_id,
              projectPrefix: this.getInitialSettings.project.project_prefix,
            },
          }
          : { name: 'Login'};
    },
    getLinkText() {
      return this.userIsLogged && this.getJWT ? 'Dashboard' : 'Sign In';
    },
    registerByInviteToken() {
      return this.$route.query.invite_token && this.$route.query.data;
    },
    inviteData() {
      try {
        return JSON.parse(base64_decode(this.$route.query.data));
      } catch (e) {
        return {};
      }
    },
  },
  methods: {
    ...mapMutations(
        {
          setJWT: 'common/setJWT',
          setInitialSettings: 'user/setInitialSettings',
        },
    ),
    ...mapActions(
        {
          fetchInitialSettings: 'user/fetchInitialSettings',
        },
    ),
    runVideo(e, video) {
      document.querySelector('.start__col--side').innerHTML = '<iframe width="100%" height="360" src="' + video +
          '?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1" frameborder="0" allowfullscreen></iframe>';
    },
    handleRegister() {
      if ( ! this.userEmail) {
        return;
      }

      if (this.hasError) {
        this.hasError = false;
        this.errorMessage = '';
      }

      this.submitted = true;
      const invite_token = this.$route.query.invite_token;

      let payload = {
        userEmail: this.userEmail,
        referrer: this.referrer,
      };

      if (invite_token) {
        payload.invite_token = invite_token;
      }

      this.$store.dispatch('user/register', payload).then(resp => {
        if (resp && resp.hasOwnProperty('data') && resp.data.hasOwnProperty('user_id')) {
          payload = {
            userEmail: resp.data.userEmail,
            userPass: resp.data.userPass,
          };

          this.$store.dispatch('user/login', payload).then(async resp => {
            this.$store.commit('common/setJWT', resp.JWT);
            await this.fetchInitialSettings();
            this.$router.push({
              name: 'Project',
              params: {
                teamId: this.getInitialSettings.team.team_id,
                projectPrefix: this.getInitialSettings.project.project_prefix,
              },
            });
          });
        }
      }).catch(error => {
        this.submitted = false;
        this.hasError = true;
        this.errorMessage = error.response.data.message;
      });
    },
  },

  watch: {
    userEmail(val) {
      if (this.hasError) {
        this.hasError = false;
        this.errorMessage = '';
      }
    },
  },
};
</script>

<style lang="postcss">
@import "styles.css";
</style>